@use '@sats-group/ui-lib/tokens/spacing';

.overview {
  $breakpoint: 1100px;
  display: flex;
  gap: spacing.$s;

  @media (max-width: $breakpoint) {
    flex-direction: column;
    width: 100%;
  }

  > * {
    max-width: 300px;
    width: 100%;

    @media (max-width: $breakpoint) {
      max-width: 100%;
    }
  }
}
