.overview {
  display: flex;
  gap: 12px;
}
@media (max-width: 1100px) {
  .overview {
    flex-direction: column;
    width: 100%;
  }
}
.overview > * {
  max-width: 300px;
  width: 100%;
}
@media (max-width: 1100px) {
  .overview > * {
    max-width: 100%;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9wYWdlcy9jYWxlbmRhci9jb21wb25lbnRzL292ZXJ2aWV3Iiwic291cmNlcyI6WyJvdmVydmlldy5zY3NzIiwiLi4vLi4vLi4vLi4vbm9kZV9tb2R1bGVzL0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvc3BhY2luZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBRUU7RUFDQSxLQ0ZFOztBRElGO0VBTEY7SUFNSTtJQUNBOzs7QUFHRjtFQUNFO0VBQ0E7O0FBRUE7RUFKRjtJQUtJIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQHNhdHMtZ3JvdXAvdWktbGliL3Rva2Vucy9zcGFjaW5nJztcblxuLm92ZXJ2aWV3IHtcbiAgJGJyZWFrcG9pbnQ6IDExMDBweDtcbiAgZGlzcGxheTogZmxleDtcbiAgZ2FwOiBzcGFjaW5nLiRzO1xuXG4gIEBtZWRpYSAobWF4LXdpZHRoOiAkYnJlYWtwb2ludCkge1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgd2lkdGg6IDEwMCU7XG4gIH1cblxuICA+ICoge1xuICAgIG1heC13aWR0aDogMzAwcHg7XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICBAbWVkaWEgKG1heC13aWR0aDogJGJyZWFrcG9pbnQpIHtcbiAgICAgIG1heC13aWR0aDogMTAwJTtcbiAgICB9XG4gIH1cbn1cbiIsIi8vIGh0dHBzOi8vd3d3LmZpZ21hLmNvbS9maWxlL1d6S0N3UlkwOXpuNHJ6UlZmWTBZdmRSdC9zYXRzLWRzLXN0eWxlcz9ub2RlLWlkPTQwOCUzQTg1XG4keHhzOiA0cHg7XG4keHM6IDhweDtcbiRzOiAxMnB4O1xuJG06IDE2cHg7XG4kbDogMjRweDtcbiR4bDogMzJweDtcbiR4eGw6IDY0cHg7XG4keHh4bDogMTI4cHg7XG5cbiRoZXJvOiAyNTZweDtcbiRtaW5pbXVtLXZpZXdwb3J0LXdpZHRoOiAzMjBweDtcbiJdfQ== */